@import 'modules/mixin';

.splitBannerArea{
	position: relative;
	display: flex;
	padding: 30px 0 0 0;

	@include MQ(639) {
		flex-direction: column;
	}

	.splitBannerLeft{
		width: 50%;
		padding: 0 25px;
		border-right: 1px solid #25436d;

		@include MQ(639) {
			width: 100%;
			margin-bottom: 30px;
			border-right: none;
			padding: 0 0 25px 0;
			border-bottom: 1px solid #25436d;
		}

		p{
			@include font-size(18px);
			padding: 10px 25px 15px;
			color: #fff;

			@include MQ(1023) {
				padding: 10px 10px 15px;
			}
			@include MQ(639) {
				padding: 10px 0 15px;
			}
		}
	}
	.splitBannerRight{
		width: 50%;
		padding: 0 25px;

		@include MQ(639) {
			width: 100%;
			padding: 25px 0 0 0;
		}

		p{
			@include font-size(18px);
			padding: 10px 25px 15px;
			color: #fff;

			@include MQ(1023) {
				padding: 10px 10px 15px;
			}
			@include MQ(639) {
				padding: 10px 0 15px;
			}
		}
	}
}

.splitBannerImageWrap{
	margin-bottom: 20px;

	.splitBannerImage{
		max-width: 200px;
		margin: auto;
		padding-bottom: 20px;
	}
}

.icon_128{
	max-width: 128px !important;
}