@import 'mixin';

.footer{
	// background: #f2f2f2;
	background: #2e2e2e;
	padding: 50px 0;

	.bottomTabWrapper{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 30px;

		.bottomTab{
			max-width: 25%;
			flex-basis: 25%;
			width: 100%;
			padding-right: 25px;
			@include font-size(14px);
			font-weight: 300;
			// line-height: 1.8;
			color: #7d7d7d;
			&:nth-of-type(4) {
				padding-right: 0;
			}

			@include MQ(1023) {
				max-width: 50%;
				flex-basis: 50%;
				text-align: center;
				padding-right: 0;

				&:nth-of-type(1) {
					padding-bottom: 30px;
					border-bottom: 1px solid #444242;
					border-right: 1px solid #444242;
					order: 1;	
				}
				&:nth-of-type(2) {
					padding-top: 30px;
					order: 4;	
				}
				&:nth-of-type(3) {
					padding-bottom: 30px;
					border-bottom: 1px solid #444242;
					order: 2;	
				}
				&:nth-of-type(4) {
					padding-top: 30px;
					border-right: 1px solid #444242;
					order: 3;	
				}
			}

			@include MQ(639) {
				max-width: 100%;
				flex-basis: 100%;

				&:nth-of-type(1) {
					border-right: none;
				}
				&:nth-of-type(3) {
					padding-top: 30px;
				}
				&:nth-of-type(4) {
					border-right: none;
					padding-bottom: 30px;
				}
				&:nth-of-type(2) {
					border-top: 1px solid #444242;
				}
			}

			.tabHeading{
				@include font-size(18px);
				color: #fff;
				padding-bottom: 25px;
			}
			a{
				color: #7d7d7d;
			}
			i{
				font-size: 32px;
				margin-right: 5px;
				margin-bottom: 5px;
				vertical-align: middle;
			}
			ul{
				li{
					padding: 5px 0;
				}
			}
			p{
				padding-bottom: 20px;
				line-height: 1.7;
			}
			.imiLogo{
				max-width: 209px;
				width: 100%;
				padding-bottom: 20px;

				@include MQ(1023) {
					margin: auto;
				}
			}
			.callNumber{
				@include font-size(18px);
				text-decoration: none;
			}
			.emailReservationBtn{
				// background: #7d7d7d;
				display: block;
				max-width: 200px;
				width: 100%;
				@include font-size(16px);
				color: #7d7d7d;
				text-align: center;
				padding: 8px 0;
				text-decoration: none;
				border: 1px solid #c5c5c5;

				&:hover{
					background: #7d7d7d;
					color: #fff;
				}

				@include MQ(1023) {
					margin: 0 auto;
				}
			}

			.socialTab{
				@include MQ(1023) {
					li{
						display: inline-block;

						&:last-of-type{
							a{
								i{
									margin-right: 0;
								}
								
							}
						}
					}
					a{
						i{
							margin-right: 5px;

							
						}
						span{
							display: none;
						}
					}
				}
			}
		}
	}

	.copyrightCreditWrap{
		border-top: 1px solid #585858;
		padding: 30px 0;
		text-align: center;

		.copyright{
			@include font-size(14px);
			color: #7d7d7d;
			padding-bottom: 10px;
		}
		.creditDesignDev{
			@include font-size(12px);
			color: #7d7d7d;

			a{
				// @include font-size(12px);
				color: #fff;
			}
		}
	}
}
