@import 'modules/mixin';

.navigationWrapper{
	position: relative;
	background: #004071;
	// padding: 10px 0;

	@include MQ(1279) {
		display: none;
	}

	ul{
		text-align: center;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		li{
			flex: auto;

			&.highLightNav{
				background: #046cbb;
			}

			&:hover{
				// a{
				// 	background: #046cbb;
				// }
				.dropDownContainer{
					display: block;

					// visibility:visible;
					// opacity:1;
				}
			}
			.dropDownContainer{
				position: absolute;
				left: 0;
				z-index: 100;
				width: 100%;
				// background: red;

				display: none;

				// visibility:hidden;
				// opacity:0;
				// transition:all 0.1s ease;
			
				.dropDownInner{
					max-width: 80em;
					padding: 0 25px;
					margin: auto;
					animation: smooth-entry-revers 0.4s;

					a{
						text-transform: none !important;

						&:hover{
							background: #fff;

							&:nth-of-type(even) {
								background-color: #f0f2f4;
							}
						}
					}
				}

			}


			a{
				position: relative;
				display: block;
				@include font-size(18px);
				font-weight: 500;
				text-transform: uppercase;
				color: #fff;
				padding: 12px 0;
				text-decoration: none;

				@include MQ(1279) {
					@include font-size(18px);
				}

				&:hover{
					background: #046cbb;

					.dropDownContainer{
						display: block;
						// visibility:visible;
						// opacity:1;
					}
				}

				&.dropDownIndicator{


					// &:after {
					//   content: "";
					//   position: absolute;
					//   width: 2px;
					//   display: block;
					//   background-color: #fff;
					//   height: 10px;
    	// 			  top: 42%;
    	// 			  right: 24px;
					//   z-index: 9;
					// }
					// &:before {
					//   content: "";
					//   position: absolute;
					//   display: block;
					//   background-color: #fff;
					//   width: 10px;
					//   height: 2px;
					//   top: 49%;
					//   right: 20px;
					//   z-index: 9;
					// }

					&:after {
					  position: absolute;
					  content: '';
					  display: block;
					  top: 42%;
					  right: 18px;
					  width: 0; 
					  height: 0; 
					  border-left: 7px solid transparent;
					  border-right: 7px solid transparent;
					  
					  border-top: 7px solid #fff;
					}
				}

			}
		}
	}
}