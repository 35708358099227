@import 'mixin';


.successText{
    padding: 15px;
    @include font-size(18px);
    color: rgb(19, 116, 0);
    text-align: center;
    background: #fff;

	p{
		color: rgb(19, 116, 0);
	}
}
.errorText{
    padding: 15px;
    @include font-size(18px);
    color: hsl(0, 100%, 50%);
    text-align: center;
    background: #fff;

    p{
    	color: rgb(19, 116, 0);
    }
}


.serviceFormWrapper{
	position: relative;
	max-width: 430px;
	width: 100%;
	margin: 30px auto;
	color: #fff;

	// @include MQ(539) {
	// 	margin: 0 auto 30px;
	// }

	.formTab{
		margin-bottom: 12px;

		&.firstTab{
			margin-bottom: 25px;
		}

		&.extraGap{
			padding: 15px 0;
			margin-bottom: 0;
		}
		&.termsOfUseWrap{
			a{
				@include font-size(18px);
				color: #fff;
				text-decoration: underline;
			}

			label.error {

			    position: absolute;
			    bottom: -25px;
			    left: 0;
			    font-size: 16px;
			    color: #f84348;
			}
		}
		.captchaWrap{
			max-width: 305px;
			width: 100%;
			// height: 80px;
			// overflow: hidden;
			margin-top: 30px;
			overflow-x: auto;
		}
	}
	.radioCheckboxLabel {
	  	position: relative;
	 	margin: 20px 0;
	 	@include font-size(18px);
	 	font-weight: 300;	 	 
	
		&.radioBtnWrap{
			margin-right: 30px;
		}
		&.checkboxLbl { 
			display: block; 
		}

		input[type="radio"],
		input[type="checkbox"]{
		  position: absolute;
		  margin: 5px;
		  padding: 0;
		  /* for mobile accessibility (iOS Label Bug) */
		  visibility: hidden;
		}
		
		.label-visible {
		  position: relative;
		  padding-left: 2em;
		  margin-bottom: 0;
		  @include font-size(20px);
		  font-weight: 300;
		  color: #fff;
		  display: inline-block;
		}
		.showRadioCheckbox{
		  position: absolute;
		  display: block;
		  top: 0;
		  left: 3px;
		  width: 30px;
		  height: 30px;
		  border: 1px solid #fff;
		  background-color: white;		 	

		  &.showRadio { border-radius: 50%; }
		  &.showRadio:after { border-radius: 50%; }
		}
		.showRadioCheckbox:after {
		  content: "✔";
		  @include font-size(20px);
		  padding-left: 5px;
		  line-height: 1.4;
		  color: #fff;
		  display: none;
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  width: 28px;
		  height: 28px;
		  background: rgb(115,205,3);
		  transform: translateX(-50%) translateY(-50%);
		}
		input[type="radio"]:checked + div .showRadio:after,
		input[type="checkbox"]:checked + div .showRadioCheckbox:after { display: block; }
	}

	.blockLabel{
		display: block;
		margin-bottom: 3px;
		@include font-size(20px);
		font-weight: 300;
		color: #fff;
	}
	label.error{
		@include font-size(16px);
		color: #f84348;
	}
	input[type="text"], input[type="email"] {
		display: block;
		max-width: 100%;
		width: 100%;
		background: #fff;
		border: 1px solid #ddd;
		padding: 8px 10px;
		border-radius: 2px;
		@include font-size(18px);
		color: #000;
		outline: none;

		&.error{
			border: 1px solid #f84348;
		}
	}
	textarea{
		display: block;
		max-width: 100%;
		width: 100%;
		background: #fff;
		border: 1px solid #ddd;
		padding: 8px 10px;
		min-height: 100px;
		resize: vertical;
		border-radius: 2px;
		@include font-size(18px);
		color: #000;
		outline: none;

		&.error{
			border: 1px solid #f84348;
		}
	}
	input[type="submit"] {
		display: block;
		max-width: 100%;
		width: 100%;
		border: 1px solid rgb(115,205,3);
		padding: 15px 10px;
		border-radius: 2px;
		@include font-size(18px);
		color: #fff;
		outline: none;
		margin-top: 20px;

		background: rgb(115,205,3);
		background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(91,175,3,1) 100%);

		&:hover{
			background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(115,205,3,1) 100%);
		}
	}

}

// cookie & privacy policy and terms
.cookiePrivacyTermsCol {
	margin-top: 30px;
    // margin-bottom: 15px;
    position: relative;
    width: 100%;

    a{
    	color: #fff;
    	text-decoration: underline;
    }

    input[type="checkbox"] {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 27px;
        height: 27px;
        background: #000;
        font-size: 24px;
        border:none;
        outline:3px solid #bbb;

        &.error {
            outline: 3px solid #f84348;
        }
    }
    label{
        color: #fff;
        display: block;
        margin-bottom: 0;
        // padding: 12px;
        @include font-size(20px);
        font-weight: 300;
        padding-left: 2em;
        position: relative;
        // box-shadow: 0 0 0 1px rgba(13, 12, 12, 0.25); 
        transition: all 0.3s ease;

        &.error{
            padding: 0 0 5px 0;
            display: block;
            color: #f84348;
            box-shadow: none;
        }
    }
}