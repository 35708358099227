@import 'mixin';

.bgvideoWrap{
    // margin-top: 153px;
    // max-height: calc(100vh - 143px);
    // max-height: calc(100vh - 143px);
    overflow: hidden;
    position: relative;
    background: #152855;
    min-height: 400px;
    max-height: 470px;

    @include MQ(1023) {
        margin-top: 0;
    }

    @include MQ(539) {
        // margin-top: 113px;
    }

    &:before{
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.25%;
    }

    .videoContent{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .videoBgImg{
            height: 100%;
            width: 100%;
            background: rgba(#000, 0.7);
            background-size: cover;
            position: relative;

            .downAnchor{
                height: 64px;
                width: 64px;
                left: 50%;
                position: absolute;
                z-index: 10;
                display: block;
                transform: translate(-50%, 0);
                bottom: 40px;
                padding: 0;

                @include MQ(539) {
                    width: 40px;
                    height: 40px;
                }
            }

            .videoOverlay{
                width: 100%;
                height: 100%;
                background: rgba(#152855, 0.5);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }
            .textContent{
                position: relative;
                z-index: 3;
                margin: 0 auto;
                top: 50%;
                transform: translateY(-50%);
                max-width: 80%;
                font-family: 'Roboto';
                text-align: center;
                color: #fff;
                // font-size: 5vw;
                line-height: 1.3;

            }

            .buttonWrapper{
                display: flex;
                max-width: 280px;
                flex-basis: 100%;
                justify-content: space-between;
                margin: 15px auto 0;
                flex-wrap: wrap;
                text-align: center;

                .sliderBtn{
                    border-radius: 5px;
                    display: inline-block;
                    padding: 12px 20px 10px;
                    color: #fff;
                    text-decoration: none;
                    text-transform: uppercase;
                    text-align: center;

                    margin-top: 4px;
                    background: rgb(115,205,3);
                    background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(91,175,3,1) 100%);

                    @include MQ(767) {
                        padding: 10px 20px 8px;
                    }

                    &:hover{
                        background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(115,205,3,1) 100%);
                    }
                }

                .watchvideoBtn{
                    border-radius: 5px;
                    display: inline-block;
                    padding: 12px 20px 10px;
                    color: #fff;
                    text-decoration: none;
                    text-transform: uppercase;
                    text-align: center;

                    margin-top: 4px;
                    background: rgba(235,34,39,1);
                    background: linear-gradient(to bottom, rgba(235,34,39,1) 0%,rgba(246,41,12,1) 100%);

                    @include MQ(767) {
                        padding: 10px 20px 8px;
                    }

                    &:hover{
                        background: linear-gradient(to bottom, rgba(235,34,39,1) 0%,rgba(235,34,39,1) 100%);
                    }
                }
            }

            
        }
    }
    .bgVideo{
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        video{
            height: auto;
            left: 50%;
            min-height: 101%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            width: auto;
            z-index: 1;
            transform: translate(-50%, -50%);
            // max-height: 101%;
        }
    }
}

.textLarge{
    font-size: 4vw;
    line-height: 1.5;
    font-weight: 300;

    @include MQ(539) {
        font-size: 30px;
    }
}