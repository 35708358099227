@import 'mixin';

.accreditationWrap {
	background: #57585a;
	padding: 20px 0;

	.logsList{
		
		text-align: center;

		li{
			display: inline-block;
			max-width: 230px;
			margin: 5px 10px;

		}
	}
}