@import "mixin";

.servicesTabsWrapper{
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	// max-width: 80em;
	// padding: 0 25px;
	// margin: auto;
	
	a{
		display: block;
		width: 14.28571428571429%; // 100/7
		text-decoration: none;
		background: #f8f8f8;

		@include MQ(1279) {
			width: 20%;
			border: 1px solid #fff;
		}
		@include MQ(1023) {
			width: 25%;
		}
		@include MQ(767) {
			width: 33%;
		}
		@include MQ(539) {
			width: 50%;
		}

		&:nth-of-type(even) {
			background-color: #f0f2f4;

			@include MQ(539) {
				background: #f8f8f8;
			}
		}

		&:hover{
			// background: #046cbb;
			// span{
			// 	color: #ffffff !important;
			// }
			img{
				margin-top: -10px;
			}
		}

		.servicesTab{
			min-height: 176px;
			display: flex;
			flex-direction: column;

			.serviceImageIcon{
				position: relative;
				flex: 1;
				padding: 10px;
				text-align: center;
				min-height: 130px;

				@include MQ(1023) {
					min-height: 1px;
				}

				img{
					max-width: 80px;
					@include absolutecenter('both');
					// margin-top: 0;
					transition: all 0.3s ease;
				}
			}
			span{
				display: block;
				font-weight: 500;
				@include font-size(14px);
				color: #004071;
				padding: 5px;
				text-align: center;
			}
		}
	}
	
}