@import 'mixin';

.contactBannerArea{
	position: relative;
	display: flex;
	padding: 30px 0 0 0;
	flex-wrap: wrap;

	.contactBannerTab{
		max-width: 33.33333333333333%;
		flex-basis: 33.33333333333333%;
		border-right: 1px solid #25436d;
		text-align: center;

		&:last-of-type{
			border-right: none;
		}

		@include MQ(767) {
			max-width: 100%;
			flex-basis: 100%;
			margin-bottom: 30px;
			border-right: none;
			padding: 0 0 25px 0;
			border-bottom: 1px solid #25436d;

			&:last-of-type{
				border-bottom: none;
			}
		}

		.contactTabHeading{
			@include font-size(26px);
			color: #fff;
			font-weight: 500;

			@include MQ(1023) {
				@include font-size(22px);
			}
			@include MQ(767) {
				@include font-size(20px);
			}
			// @include MQ(539) {
			// 	@include font-size(20px);
			// }
		}

		.bannerImageWrap{
			margin-bottom: 20px;

			.bannerImage{
				max-width: 200px;
				margin: auto;
				padding-bottom: 20px;
			}
		}

		p{
			@include font-size(18px);
			padding: 10px 25px 15px;
			color: #fff;

			@include MQ(1023) {
				padding: 10px 10px 15px;
			}
			@include MQ(639) {
				padding: 10px 0 15px;
			}
		}
	}
}

.contactusBannerIcon{
	max-width: 128px !important;
}

.contactTelephone{
	color: #fff !important;
	font-weight: 500;
	border-bottom: none !important;
	transition-duration: none !important;

	span{
		max-width: 32px;
		display: inline-block;
		margin-right: 5px;
	}
}

.mapBg{ 
	position:relative;
	width:100%;
	height:550px;
	overflow:hidden;
	background-size:cover;
	
 	box-shadow: inset 0 -10px 10px -10px #767678;

 	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height:550px;
		border:0;
		
		background-size:cover;
	}
}
