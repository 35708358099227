@import 'mixin';

.headerTab{
	background: #fff;
	padding: 30px 0 35px;

	@include MQ(1279) {
		padding: 75px 0 35px 0;
		border-bottom: 1px solid #e8e8e8;
	}

	.headerTabWrapper{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@include MQ(639) {
			flex-direction: column;
		}

		.logo{
			width: 275px;
			position: relative;

			@include MQ(639) {
				margin: auto;
				// max-width: 225px;
				// width: 100%;
				margin-bottom: 15px;
			}

			#dom_overlay_container{
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		
		.headerMiddleTab{

			@include MQ(1279) {
				display: none;
			}

			.motTestCentre{
				min-width: 285px;
				@include font-size(14px);
				font-weight: 400;
				color: #004071;
				text-transform: uppercase;
				text-align: center;
				display: block;
				text-align: center;
				letter-spacing: 2px;
				padding: 10px 0 10px 0;
				margin-bottom: 10px;
				border: 1px solid #dcdcdc;
				text-decoration: none;
				border-radius: 5px;

				background: #fff;
				background: linear-gradient(to bottom, #fff 0%,#fff 100%);

				&:hover{
					background: linear-gradient(to bottom, #fff 0%,#dedede 100%);
				}

				span{
					display: inline-block;
					max-width: 24px;
					width: 100%;
					margin-right: 5px;
				}
			}
			
			.headerCallNumber{

				// @include MQ(1279) {
				// 	display: none;
				// }

				a{
					@include font-size(42px);
					font-weight: 300;
					color: #004071;
					text-decoration: none !important;
					line-height: 1.2;
				}
				span{
					@include font-size(14px);
					font-weight: 300;
					color: #6c6d6f;
					text-transform: uppercase;
					text-align: center;
					display: block;
					text-align: center;
					letter-spacing: 2px;
				}
			}
		}

		
		.headerRightTab{
			width: 265px;

			@include MQ(639) {
				margin: auto;
			}

			.dvlaText{
				@include font-size(14px);
				color: #6c6d6f;
				letter-spacing: 1.5px;
				font-weight: 300;
				text-align: center;
				text-transform: uppercase;
			}

			.headerRightBtn{
				border-radius: 5px;
				display: flex;
				padding: 14px 0;
				color: #fff;
				text-decoration: none;

				i{
					width: 65px;
					text-align: center;

					img{
						width: 24px;
						margin: -5px 0 0 0;
					}
				}
			}
			.bookAnMotBtn{
				margin-top: 10px;
				background: rgb(0,101,181);
				background: linear-gradient(to bottom, rgba(0,101,181,1) 0%,rgba(0,33,116,1) 100%);

				&:hover{
					background: linear-gradient(to bottom, rgba(0,101,181,1) 0%,rgba(0,101,181,1) 100%);
				}
			}
			.bookCarServiceBtn{
				margin-top: 4px;
				background: rgb(115,205,3);
				background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(91,175,3,1) 100%);

				&:hover{
					background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(115,205,3,1) 100%);
				}

				img{
					width: 35px !important;
				}
			}
			.motTestCentreBtn{
				@include font-size(14px);
				color: #004071;
				border-radius: 5px;
				text-decoration: none;
				border: 1px solid #004071;
				margin-bottom: 10px;
				background: #fff;

				display: none;				

				@include MQ(1279) {
					display: flex;
				}
			}
			// .motTestCentreMobile{
			// 	display: flex;
			// 	align-items: center;
			// 	padding: 14px 0;
			// 	color: #004071;
			// 	margin-top: 5px;
			// 	border-radius: 5px;
			// 	text-decoration: none;
			// 	border: 1px solid #004071;
			// 	@include font-size(13px);

			// 	i{
			// 		width: 65px;
			// 		text-align: center;

			// 		img{
			// 			width: 24px;
			// 			margin: -5px 0 0 0;
			// 		}
			// 	}
			// }
		}
	}
}

