@import 'modules/typography-variables';

body{
	// background:rgba(#f2f2f2, 1);
	background: #2e2e2e;
	font:normal 100%/1.5 $body-font;
	color:#383838;
	font-weight: 300;
	margin:0;
	text-align: left;
}
.wrapper {
	// position:relative;
	width:100%;
	max-width:80em; /*1280/16*/
	// max-width: 45.625em; /*730/16*/
	padding: 0 25px;
	margin:0 auto;

	@include MQ(767) {
		padding: 0 20px;
	}
}
.container{
	// width: 100%;
	background: #fff;
	// padding-bottom: 30px;

}