@import 'modules/mixin';

.splitBannerArea{
	position: relative;
	display: flex;
	padding: 30px 0 0 0;

	@include MQ(639) {
		flex-direction: column;
	}

	.splitBannerTab{
		max-width: 33.33333333333333%;
		width: 33.33333333333333%;
		flex-basis: 33.33333333333333%;
		padding: 0 25px;
		border-right: 1px solid #25436d;

		&:last-of-type{
			border-right: none;
		}

		@include MQ(639) {
			max-width: 100%;
			flex-basis: 100%;
			width: 100%;
			margin-bottom: 30px;
			border-right: none;
			padding: 0 0 25px 0;
			border-bottom: 1px solid #25436d;

			&:last-of-type{
				border-bottom: none;
				padding-bottom: 0;
			}
		}

		.bookNowBtn{
			min-width: 150px;
			margin-top: 20px;
		}
		.bannerHeading {
			@include MQ(850) {
				@include font-size(22px);
			}
			@include MQ(767) {
				@include font-size(18px);
			}
			@include MQ(639) {
				@include font-size(22px);
			}

		}
		p{
			@include font-size(18px);
			padding: 10px 25px 15px;
			color: #fff;

			@include MQ(1023) {
				padding: 10px 10px 15px;
			}
			@include MQ(639) {
				padding: 10px 0 15px;
			}
		}

		.splitBannerImageWrap{
			margin-bottom: 20px;

			.splitBannerImage{
				max-width: 200px;
				margin: auto;
				padding-bottom: 20px;

				@include MQ(850) {
					max-width: 150px;
				}
				@include MQ(639) {
					padding-bottom: 0;
				}
			}
		}
	}
	// .splitBannerRight{
	// 	width: 50%;
	// 	padding: 0 25px;

	// 	@include MQ(639) {
	// 		width: 100%;
	// 		padding: 25x 0 0 0;
	// 	}

	// 	p{
	// 		@include font-size(18px);
	// 		padding: 10px 25px 15px;
	// 		color: #fff;

	// 		@include MQ(1023) {
	// 			padding: 10px 10px 15px;
	// 		}
	// 		@include MQ(639) {
	// 			padding: 10px 0 15px;
	// 		}
	// 	}
	// }
}



.icon_128{
	max-width: 128px !important;
}