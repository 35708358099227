@import 'modules/mixin';

.sectionContaner{
	text-align: center;
	
	h1{
		font-size: 30px;
		font-weight: 700;
		padding-bottom: 20px;
	}
	p{
		font-size: 18px;
		line-height: 1.7;
	}
}
.homeAboutTab{
	padding: 30px 100px 80px 100px;
}

.serviceWrapHome{
	padding: 50px 0;
}

.homeServicesWrap{
	display: flex;
	padding: 30px 0 0 0;
	justify-content: space-between;

	.homeServiceTab{
		width: 23%;
		height: 300px;
		border: 1px solid #cecece;
	}
}

.MOT-FormWrap{
	position: relative;
	max-width: 450px;
	margin: 30px auto;

	input[type="text"] {
		width: 100%;
		padding: 8px 10px;
		border: 1px solid #cecece;
		border-radius: 3px;
		margin-bottom: 15px;
		text-align: center;
	}

	button[type="submit"] {
		width: 200px;
		margin: 20px 0 0 0;
		padding: 10px 5px;
		text-align: center;
		background: #008eff;
		color: #fff;
		border:none;
		font-size: 18px;
	}

}