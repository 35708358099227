@charset "UTF-8";
/*-HTML5 Reset :: style.css---------------------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Raleway:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Raleway:400,500,600,700");
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
object,
embed {
  max-width: 100%;
}

img {
  /*display:block;*/
  vertical-align: bottom;
}

/* force a vertical scrollbar to prevent a jumpy page */
/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

.ie7 input[type="checkbox"] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

li.clearfix {
  display: list-item;
}

/*Hide these rules from IE-mac */
* html .clearfix {
  height: 1%;
}

.clearfix {
  *zoom: 1;
}

/*-End HTML5 Reset :: style.css------------------------------------------------------------------------------------------*/
.hidden, .invisible {
  visibility: hidden;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.boldcase {
  font-weight: 700;
}

.normalcase {
  font-weight: 400;
}

.textdec-underline {
  text-decoration: underline;
}

.textdec-normal, a {
  text-decoration: none;
}

.Text-Idnt {
  text-indent: -99999px;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

img.floatleft {
  float: left;
  margin: 0 10px 0 0;
}

img.floatright {
  float: right;
  margin: 0 0 0 10px;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

a {
  color: #000;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

a:hover {
  text-decoration: underline;
}

p:last-child {
  padding-bottom: 0 !important;
}

.noPaddingTop {
  padding-top: 0 !important;
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}

.noPaddingLeft {
  padding-left: 0 !important;
}

.noPaddingRight {
  padding-right: 0 !important;
}

@keyframes smooth-entry {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1.8em);
    -moz-transform: translateY(-1.8em);
    -ms-transform: translateY(-1.8em);
    -o-transform: translateY(-1.8em);
    transform: translateY(-1.8em);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes smooth-entry-revers {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1.8em);
    -moz-transform: translateY(1.8em);
    -ms-transform: translateY(1.8em);
    -o-transform: translateY(1.8em);
    transform: translateY(1.8em);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

.textWhite {
  color: #fff !important;
}

.textWhite {
  color: #fff !important;
}

body {
  background: #2e2e2e;
  font: normal 100%/1.5 "Roboto", Arial, Helvetica, sans-serif;
  color: #383838;
  font-weight: 300;
  margin: 0;
  text-align: left;
}

.wrapper {
  width: 100%;
  max-width: 80em;
  /*1280/16*/
  padding: 0 25px;
  margin: 0 auto;
}

@media (max-width: 47.9375em) {
  .wrapper {
    padding: 0 20px;
  }
}

.container {
  background: #fff;
}

.headerTab {
  background: #fff;
  padding: 30px 0 35px;
}

@media (max-width: 79.9375em) {
  .headerTab {
    padding: 75px 0 35px 0;
    border-bottom: 1px solid #e8e8e8;
  }
}

.headerTab .headerTabWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 39.9375em) {
  .headerTab .headerTabWrapper {
    flex-direction: column;
  }
}

.headerTab .headerTabWrapper .logo {
  width: 275px;
  position: relative;
}

@media (max-width: 39.9375em) {
  .headerTab .headerTabWrapper .logo {
    margin: auto;
    margin-bottom: 15px;
  }
}

.headerTab .headerTabWrapper .logo #dom_overlay_container {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 79.9375em) {
  .headerTab .headerTabWrapper .headerMiddleTab {
    display: none;
  }
}

.headerTab .headerTabWrapper .headerMiddleTab .motTestCentre {
  min-width: 285px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #004071;
  text-transform: uppercase;
  text-align: center;
  display: block;
  text-align: center;
  letter-spacing: 2px;
  padding: 10px 0 10px 0;
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  text-decoration: none;
  border-radius: 5px;
  background: #fff;
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}

.headerTab .headerTabWrapper .headerMiddleTab .motTestCentre:hover {
  background: linear-gradient(to bottom, #fff 0%, #dedede 100%);
}

.headerTab .headerTabWrapper .headerMiddleTab .motTestCentre span {
  display: inline-block;
  max-width: 24px;
  width: 100%;
  margin-right: 5px;
}

.headerTab .headerTabWrapper .headerMiddleTab .headerCallNumber a {
  font-size: 42px;
  font-size: 2.625rem;
  font-weight: 300;
  color: #004071;
  text-decoration: none !important;
  line-height: 1.2;
}

.headerTab .headerTabWrapper .headerMiddleTab .headerCallNumber span {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 300;
  color: #6c6d6f;
  text-transform: uppercase;
  text-align: center;
  display: block;
  text-align: center;
  letter-spacing: 2px;
}

.headerTab .headerTabWrapper .headerRightTab {
  width: 265px;
}

@media (max-width: 39.9375em) {
  .headerTab .headerTabWrapper .headerRightTab {
    margin: auto;
  }
}

.headerTab .headerTabWrapper .headerRightTab .dvlaText {
  font-size: 14px;
  font-size: 0.875rem;
  color: #6c6d6f;
  letter-spacing: 1.5px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

.headerTab .headerTabWrapper .headerRightTab .headerRightBtn {
  border-radius: 5px;
  display: flex;
  padding: 14px 0;
  color: #fff;
  text-decoration: none;
}

.headerTab .headerTabWrapper .headerRightTab .headerRightBtn i {
  width: 65px;
  text-align: center;
}

.headerTab .headerTabWrapper .headerRightTab .headerRightBtn i img {
  width: 24px;
  margin: -5px 0 0 0;
}

.headerTab .headerTabWrapper .headerRightTab .bookAnMotBtn {
  margin-top: 10px;
  background: #0065b5;
  background: linear-gradient(to bottom, #0065b5 0%, #002174 100%);
}

.headerTab .headerTabWrapper .headerRightTab .bookAnMotBtn:hover {
  background: linear-gradient(to bottom, #0065b5 0%, #0065b5 100%);
}

.headerTab .headerTabWrapper .headerRightTab .bookCarServiceBtn {
  margin-top: 4px;
  background: #73cd03;
  background: linear-gradient(to bottom, #73cd03 0%, #5baf03 100%);
}

.headerTab .headerTabWrapper .headerRightTab .bookCarServiceBtn:hover {
  background: linear-gradient(to bottom, #73cd03 0%, #73cd03 100%);
}

.headerTab .headerTabWrapper .headerRightTab .bookCarServiceBtn img {
  width: 35px !important;
}

.headerTab .headerTabWrapper .headerRightTab .motTestCentreBtn {
  font-size: 14px;
  font-size: 0.875rem;
  color: #004071;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid #004071;
  margin-bottom: 10px;
  background: #fff;
  display: none;
}

@media (max-width: 79.9375em) {
  .headerTab .headerTabWrapper .headerRightTab .motTestCentreBtn {
    display: flex;
  }
}

.responsiveHeaderWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1f3655;
  display: none !important;
  z-index: 1150;
}

@media (max-width: 79.9375em) {
  .responsiveHeaderWrap {
    display: block !important;
  }
}

.responsiveHeaderWrap .responsiveHeaderTopWrap {
  display: flex;
  border-bottom: 1px solid #2e4e7b;
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .navBtn {
  max-width: 50%;
  flex-basis: 50%;
  border-right: 1px solid #2e4e7b;
  padding: 10px 15px;
  text-align: center;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  line-height: 2;
  cursor: pointer;
}

@media (max-width: 33.6875em) {
  .responsiveHeaderWrap .responsiveHeaderTopWrap .navBtn {
    max-width: 45%;
    flex-basis: 45%;
    text-align: left;
    padding: 10px 5px 10px 15px;
    font-size: 16px;
    font-size: 1rem;
    line-height: 2.5;
  }
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .navBtn.activeNav span {
  background: transparent;
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .navBtn.activeNav span:before {
  margin-top: 0;
  background: #ff0000;
  transform: rotate(45deg);
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .navBtn.activeNav span:after {
  margin-top: 0;
  background: #ff0000;
  transform: rotate(-45deg);
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .navBtn span {
  position: relative;
  width: 20px;
  height: 2px;
  background: #60b502;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -3px;
  transition: all 0.3s ease;
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .navBtn span:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  margin-top: -8px;
  background: #60b502;
  transition: all 0.3s ease;
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .navBtn span:after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 8px;
  background: #60b502;
  transition: all 0.3s ease;
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .responsiveHeaderCallTab {
  max-width: 50%;
  flex-basis: 50%;
  text-align: right;
  font-family: 'Roboto';
  font-size: 20px;
  font-size: 1.25rem;
  color: #fff;
  line-height: 0.6em;
  letter-spacing: 0.03em;
  text-align: center;
}

@media (max-width: 33.6875em) {
  .responsiveHeaderWrap .responsiveHeaderTopWrap .responsiveHeaderCallTab {
    max-width: 55%;
    flex-basis: 55%;
    text-align: right;
  }
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .responsiveHeaderCallTab a {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  text-decoration: none !important;
}

.responsiveHeaderWrap .responsiveHeaderTopWrap .responsiveHeaderCallTab a i {
  color: #60b502;
}

.responsiveHeaderWrap .responsiveNavigationWrap {
  background: #f0f2f4;
  display: block;
  position: relative;
  width: 50%;
  left: -100%;
  transition: all 0.4s ease;
}

@media (max-width: 33.6875em) {
  .responsiveHeaderWrap .responsiveNavigationWrap {
    width: 100%;
  }
}

.responsiveHeaderWrap .responsiveNavigationWrap ul {
  position: absolute;
  left: 0;
  right: 0;
  background: #f0f2f4;
  z-index: 1000;
  padding-bottom: 100px;
  height: -moz-calc(100vh - 60px);
  height: -webkit-calc(100vh - 60px);
  height: calc(100vh - 60px);
  overflow-y: scroll;
}

.responsiveHeaderWrap .responsiveNavigationWrap ul li {
  display: list-item;
}

.responsiveHeaderWrap .responsiveNavigationWrap ul li a {
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
  color: #004071;
  text-decoration: none;
  padding: 8px 15px;
  border-bottom: 1px solid #d1dfed;
  text-align: left;
}

.responsiveHeaderWrap .responsiveNavigationWrap ul li a i {
  width: 32px;
  margin-right: 15px;
}

.responsiveHeaderWrap .responsiveNavigationWrap ul li a img {
  width: 32px;
}

.responsiveHeaderWrap .responsiveNavigationWrap ul li ul {
  position: relative;
}

.responsiveHeaderWrap .responsiveNavigationWrap ul li ul a {
  font-weight: 300;
}

.responsiveHeaderWrap .responsiveNavigationWrap.activeNav {
  left: 0;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 110;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s 0.3s;
}

.overlay.activeNav {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}

@media (min-width: 80em) {
  .overlay.activeNav {
    visibility: hidden !important;
  }
}

html.activeNav {
  position: fixed;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.textContent {
  position: relative;
}

@media (max-width: 39.9375em) {
  .textContent {
    padding-bottom: 30px;
  }
}

.wrapTextMobile {
  position: relative;
  transition: max-height 0.15s ease-out;
}

@media (max-width: 39.9375em) {
  .wrapTextMobile {
    max-height: 300px;
    overflow: hidden;
  }
  .wrapTextMobile.showFullText {
    max-height: 100%;
    padding-bottom: 40px;
  }
}

.wrapTextMobile .tapMetoShow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: none;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 0px;
  line-height: 1.2;
  color: #1f3655;
  background: #ffffff;
  background: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 0%, #bab7b7 100%);
}

@media (max-width: 39.9375em) {
  .wrapTextMobile .tapMetoShow {
    display: block;
  }
}

.bgvideoWrap {
  overflow: hidden;
  position: relative;
  background: #152855;
  min-height: 400px;
  max-height: 470px;
}

@media (max-width: 63.9375em) {
  .bgvideoWrap {
    margin-top: 0;
  }
}

.bgvideoWrap:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

.bgvideoWrap .videoContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bgvideoWrap .videoContent .videoBgImg {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  background-size: cover;
  position: relative;
}

.bgvideoWrap .videoContent .videoBgImg .downAnchor {
  height: 64px;
  width: 64px;
  left: 50%;
  position: absolute;
  z-index: 10;
  display: block;
  transform: translate(-50%, 0);
  bottom: 40px;
  padding: 0;
}

@media (max-width: 33.6875em) {
  .bgvideoWrap .videoContent .videoBgImg .downAnchor {
    width: 40px;
    height: 40px;
  }
}

.bgvideoWrap .videoContent .videoBgImg .videoOverlay {
  width: 100%;
  height: 100%;
  background: rgba(21, 40, 85, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.bgvideoWrap .videoContent .videoBgImg .textContent {
  position: relative;
  z-index: 3;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  max-width: 80%;
  font-family: 'Roboto';
  text-align: center;
  color: #fff;
  line-height: 1.3;
}

.bgvideoWrap .videoContent .videoBgImg .buttonWrapper {
  display: flex;
  max-width: 280px;
  flex-basis: 100%;
  justify-content: space-between;
  margin: 15px auto 0;
  flex-wrap: wrap;
  text-align: center;
}

.bgvideoWrap .videoContent .videoBgImg .buttonWrapper .sliderBtn {
  border-radius: 5px;
  display: inline-block;
  padding: 12px 20px 10px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  margin-top: 4px;
  background: #73cd03;
  background: linear-gradient(to bottom, #73cd03 0%, #5baf03 100%);
}

@media (max-width: 47.9375em) {
  .bgvideoWrap .videoContent .videoBgImg .buttonWrapper .sliderBtn {
    padding: 10px 20px 8px;
  }
}

.bgvideoWrap .videoContent .videoBgImg .buttonWrapper .sliderBtn:hover {
  background: linear-gradient(to bottom, #73cd03 0%, #73cd03 100%);
}

.bgvideoWrap .videoContent .videoBgImg .buttonWrapper .watchvideoBtn {
  border-radius: 5px;
  display: inline-block;
  padding: 12px 20px 10px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  margin-top: 4px;
  background: #eb2227;
  background: linear-gradient(to bottom, #eb2227 0%, #f6290c 100%);
}

@media (max-width: 47.9375em) {
  .bgvideoWrap .videoContent .videoBgImg .buttonWrapper .watchvideoBtn {
    padding: 10px 20px 8px;
  }
}

.bgvideoWrap .videoContent .videoBgImg .buttonWrapper .watchvideoBtn:hover {
  background: linear-gradient(to bottom, #eb2227 0%, #eb2227 100%);
}

.bgvideoWrap .bgVideo {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.bgvideoWrap .bgVideo video {
  height: auto;
  left: 50%;
  min-height: 101%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.textLarge {
  font-size: 4vw;
  line-height: 1.5;
  font-weight: 300;
}

@media (max-width: 33.6875em) {
  .textLarge {
    font-size: 30px;
  }
}

.sliderWrap {
  position: relative;
  padding: 0 0 0 0;
}

@media (max-width: 53.125em) {
  .sliderWrap {
    padding-top: 0;
  }
  .sliderWrap .flex-direction-nav {
    display: none !important;
  }
  .sliderWrap .flex-viewport {
    margin-bottom: 40px !important;
  }
  .sliderWrap .flex-control-nav {
    display: block !important;
    bottom: -30px;
  }
  .sliderWrap .flex-control-nav a {
    background: #d3d3d3 !important;
    box-shadow: none !important;
  }
  .sliderWrap .flex-control-nav a.flex-active {
    background: #7a9ecf !important;
  }
}

.sliderContentWrap {
  position: relative;
  padding: 80px 0;
  text-align: center;
}

@media (max-width: 63.9375em) {
  .sliderContentWrap {
    padding: 50px 0;
  }
}

@media (max-width: 39.9375em) {
  .sliderContentWrap {
    padding: 30px 0;
  }
}

.sliderContentWrap .iconWrap {
  text-align: center;
}

.sliderContentWrap .iconWrap img {
  width: auto !important;
  margin: auto;
}

.sliderContentWrap .sliderMainHeading {
  font-size: 48px;
  font-size: 3rem;
  font-weight: 500;
  color: #cb1d2a;
  padding: 20px 0 10px;
}

@media (max-width: 47.9375em) {
  .sliderContentWrap .sliderMainHeading {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media (max-width: 33.6875em) {
  .sliderContentWrap .sliderMainHeading {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.sliderContentWrap .sliderText {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 500;
  color: #004071;
  padding: 0 0 20px;
}

@media (max-width: 47.9375em) {
  .sliderContentWrap .sliderText {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (max-width: 33.6875em) {
  .sliderContentWrap .sliderText {
    font-size: 16px;
    font-size: 1rem;
  }
}

.sliderContentWrap .sliderBtn {
  border-radius: 5px;
  display: inline-block;
  padding: 12px 20px 10px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  margin-top: 4px;
  background: #73cd03;
  background: linear-gradient(to bottom, #73cd03 0%, #5baf03 100%);
}

@media (max-width: 47.9375em) {
  .sliderContentWrap .sliderBtn {
    padding: 10px 20px 8px;
  }
}

.sliderContentWrap .sliderBtn:hover {
  background: linear-gradient(to bottom, #73cd03 0%, #73cd03 100%);
}

.sliderVideo {
  position: relative;
  padding-bottom: 56.25%;
}

.sliderVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.servicesTabsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.servicesTabsWrapper a {
  display: block;
  width: 14.28571428571429%;
  text-decoration: none;
  background: #f8f8f8;
}

@media (max-width: 79.9375em) {
  .servicesTabsWrapper a {
    width: 20%;
    border: 1px solid #fff;
  }
}

@media (max-width: 63.9375em) {
  .servicesTabsWrapper a {
    width: 25%;
  }
}

@media (max-width: 47.9375em) {
  .servicesTabsWrapper a {
    width: 33%;
  }
}

@media (max-width: 33.6875em) {
  .servicesTabsWrapper a {
    width: 50%;
  }
}

.servicesTabsWrapper a:nth-of-type(even) {
  background-color: #f0f2f4;
}

@media (max-width: 33.6875em) {
  .servicesTabsWrapper a:nth-of-type(even) {
    background: #f8f8f8;
  }
}

.servicesTabsWrapper a:hover img {
  margin-top: -10px;
}

.servicesTabsWrapper a .servicesTab {
  min-height: 176px;
  display: flex;
  flex-direction: column;
}

.servicesTabsWrapper a .servicesTab .serviceImageIcon {
  position: relative;
  flex: 1;
  padding: 10px;
  text-align: center;
  min-height: 130px;
}

@media (max-width: 63.9375em) {
  .servicesTabsWrapper a .servicesTab .serviceImageIcon {
    min-height: 1px;
  }
}

.servicesTabsWrapper a .servicesTab .serviceImageIcon img {
  max-width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.servicesTabsWrapper a .servicesTab span {
  display: block;
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem;
  color: #004071;
  padding: 5px;
  text-align: center;
}

.navigationWrapper {
  position: relative;
  background: #004071;
}

@media (max-width: 79.9375em) {
  .navigationWrapper {
    display: none;
  }
}

.navigationWrapper ul {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.navigationWrapper ul li {
  flex: auto;
}

.navigationWrapper ul li.highLightNav {
  background: #046cbb;
}

.navigationWrapper ul li:hover .dropDownContainer {
  display: block;
}

.navigationWrapper ul li .dropDownContainer {
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  display: none;
}

.navigationWrapper ul li .dropDownContainer .dropDownInner {
  max-width: 80em;
  padding: 0 25px;
  margin: auto;
  animation: smooth-entry-revers 0.4s;
}

.navigationWrapper ul li .dropDownContainer .dropDownInner a {
  text-transform: none !important;
}

.navigationWrapper ul li .dropDownContainer .dropDownInner a:hover {
  background: #fff;
}

.navigationWrapper ul li .dropDownContainer .dropDownInner a:hover:nth-of-type(even) {
  background-color: #f0f2f4;
}

.navigationWrapper ul li a {
  position: relative;
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  padding: 12px 0;
  text-decoration: none;
}

@media (max-width: 79.9375em) {
  .navigationWrapper ul li a {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.navigationWrapper ul li a:hover {
  background: #046cbb;
}

.navigationWrapper ul li a:hover .dropDownContainer {
  display: block;
}

.navigationWrapper ul li a.dropDownIndicator:after {
  position: absolute;
  content: '';
  display: block;
  top: 42%;
  right: 18px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff;
}

.sectionContaner {
  text-align: center;
}

.sectionContaner h1 {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 20px;
}

.sectionContaner p {
  font-size: 18px;
  line-height: 1.7;
}

.homeAboutTab {
  padding: 30px 100px 80px 100px;
}

.serviceWrapHome {
  padding: 50px 0;
}

.homeServicesWrap {
  display: flex;
  padding: 30px 0 0 0;
  justify-content: space-between;
}

.homeServicesWrap .homeServiceTab {
  width: 23%;
  height: 300px;
  border: 1px solid #cecece;
}

.MOT-FormWrap {
  position: relative;
  max-width: 450px;
  margin: 30px auto;
}

.MOT-FormWrap input[type="text"] {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #cecece;
  border-radius: 3px;
  margin-bottom: 15px;
  text-align: center;
}

.MOT-FormWrap button[type="submit"] {
  width: 200px;
  margin: 20px 0 0 0;
  padding: 10px 5px;
  text-align: center;
  background: #008eff;
  color: #fff;
  border: none;
  font-size: 18px;
}

.pageSection {
  position: relative;
  padding: 50px 0 60px;
}

.pageSection.bgLightBlue {
  background: #e7ecf0;
}

.pageSection.bgWhite {
  background: #fff;
}

.pageSection.lightGray {
  background: #f6f6f6;
}

.pageSection.bgDarkBlue {
  background: #1f3655;
}

@media (max-width: 63.9375em) {
  .pageSection {
    padding: 40px 0;
  }
}

@media (max-width: 47.9375em) {
  .pageSection {
    padding: 30px 0;
  }
}

.pageSection .textHeadingWrap {
  padding-bottom: 30px;
}

.pageSection .textHeadingWrap .textHeading {
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 300;
  color: #004071;
  text-align: center;
  line-height: 1.2;
}

@media (max-width: 63.9375em) {
  .pageSection .textHeadingWrap .textHeading {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media (max-width: 47.9375em) {
  .pageSection .textHeadingWrap .textHeading {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.pageSection .textHeadingWrap .textSubHeading {
  font-size: 18px;
  font-size: 1.125rem;
  color: #004071;
  font-weight: 300;
  display: block;
  text-align: center;
}

@media (max-width: 63.9375em) {
  .pageSection .textHeadingWrap .textSubHeading {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (max-width: 47.9375em) {
  .pageSection .textHeadingWrap .textSubHeading {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.pageSection p {
  font-size: 18px;
  font-size: 1.125rem;
  color: #111;
  line-height: 1.7;
  font-weight: 300;
  padding-bottom: 20px;
}

@media (max-width: 63.9375em) {
  .pageSection p {
    font-size: 16px;
    font-size: 1rem;
  }
}

.pageSection p a {
  color: #000;
  padding-bottom: 2px;
  border-bottom: 1px solid #000;
  text-decoration: none;
}

.pageSection p a:hover {
  border-bottom: 1px solid transparent;
}

.pageSection .textCenter {
  text-align: center;
}

.pageSectionList {
  text-align: center;
  margin-bottom: 50px;
}

.pageSectionList.zeroBottomMargin {
  margin-bottom: 0;
}

@media (max-width: 47.9375em) {
  .pageSectionList {
    margin-bottom: 25px;
  }
}

.pageSectionList li {
  padding: 15px 0;
  border-bottom: 1px dashed #cfcfcf;
}

.testimonialSliderWrapper {
  text-align: center;
  position: relative;
  padding-top: 20px;
  padding-bottom: 40px;
}

.testimonialSliderWrapper .flex-control-nav {
  display: none;
}

@media (max-width: 53.125em) {
  .testimonialSliderWrapper {
    padding-top: 0;
  }
  .testimonialSliderWrapper .flex-direction-nav {
    display: none !important;
  }
  .testimonialSliderWrapper .flex-viewport {
    margin-bottom: 40px !important;
  }
  .testimonialSliderWrapper .flex-control-nav {
    display: block !important;
    bottom: auto;
  }
  .testimonialSliderWrapper .flex-control-nav a {
    background: #d3d3d3 !important;
    box-shadow: none !important;
  }
  .testimonialSliderWrapper .flex-control-nav a.flex-active {
    background: #7a9ecf !important;
  }
}

.testimonialText {
  padding: 0 10%;
}

@media (max-width: 47.9375em) {
  .testimonialText {
    padding: 0;
  }
}

.testimonialText p {
  font-size: 18px;
  font-size: 1.125rem;
  color: #383838;
  font-weight: 300;
  line-height: 1.8;
}

@media (max-width: 47.9375em) {
  .testimonialText p {
    font-size: 16px;
    font-size: 1rem;
  }
}

.testimonialAuthor {
  padding: 30px 10% 0;
}

.testimonialAuthor p {
  color: #ffaa06;
  font-size: 16px;
  font-size: 1rem;
}

.servicesBannerArea {
  position: relative;
  padding: 20px 0 0 0;
  text-align: center;
}

@media (max-width: 47.9375em) {
  .servicesBannerArea {
    padding: 0;
  }
}

.servicesBannerArea p {
  color: #fff;
}

.servicesBannerArea .animatedIconWrap {
  text-align: center;
  padding-bottom: 20px;
}

.servicesBannerArea .animatedIconWrap .animatedIcon {
  max-width: 200px;
  margin: auto;
  padding-bottom: 20px;
}

.servicesBannerArea .bannerHeading {
  font-size: 30px;
  font-size: 1.875rem;
  color: #fff;
  font-weight: 500;
}

@media (max-width: 63.9375em) {
  .servicesBannerArea .bannerHeading {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

@media (max-width: 47.9375em) {
  .servicesBannerArea .bannerHeading {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media (max-width: 33.6875em) {
  .servicesBannerArea .bannerHeading {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.servicesBannerArea .bannerHeading .highlight {
  color: #f92800;
  font-weight: 500;
}

.servicesBannerArea .bannerSubHeading {
  font-size: 24px;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 300;
}

@media (max-width: 63.9375em) {
  .servicesBannerArea .bannerSubHeading {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (max-width: 47.9375em) {
  .servicesBannerArea .bannerSubHeading {
    font-size: 16px;
    font-size: 1rem;
  }
}

.servicesBannerArea .bookNowBtn {
  border-radius: 5px;
  display: inline-block;
  padding: 12px 20px 10px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  margin-top: 4px;
  background: #73cd03;
  background: linear-gradient(to bottom, #73cd03 0%, #5baf03 100%);
}

@media (max-width: 47.9375em) {
  .servicesBannerArea .bookNowBtn {
    padding: 10px 20px 8px;
  }
}

.servicesBannerArea .bookNowBtn:hover {
  background: linear-gradient(to bottom, #73cd03 0%, #73cd03 100%);
}

.serviceProcessTabsWrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.serviceProcessTabsWrap .serviceProcessTab {
  width: 32.52032520325203%;
  margin-right: 1.21951219512195%;
  margin-bottom: 15px;
}

@media (max-width: 47.9375em) {
  .serviceProcessTabsWrap .serviceProcessTab {
    width: 100%;
    margin-right: 0;
  }
}

.serviceProcessTabsWrap .serviceProcessTab:nth-of-type(3n) {
  margin-right: 0;
  margin-bottom: 15px;
}

.serviceProcessTabsWrap .serviceProcessTab .imgWrap {
  text-align: center;
}

.serviceProcessTabsWrap .serviceProcessTab .textWrap {
  text-align: center;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px;
}

.serviceProcessTabsWrap .serviceProcessTab .textWrap p {
  font-weight: 300;
}

.splitBannerArea {
  position: relative;
  display: flex;
  padding: 30px 0 0 0;
}

@media (max-width: 39.9375em) {
  .splitBannerArea {
    flex-direction: column;
  }
}

.splitBannerArea .splitBannerTab {
  max-width: 33.33333333333333%;
  width: 33.33333333333333%;
  flex-basis: 33.33333333333333%;
  padding: 0 25px;
  border-right: 1px solid #25436d;
}

.splitBannerArea .splitBannerTab:last-of-type {
  border-right: none;
}

@media (max-width: 39.9375em) {
  .splitBannerArea .splitBannerTab {
    max-width: 100%;
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 30px;
    border-right: none;
    padding: 0 0 25px 0;
    border-bottom: 1px solid #25436d;
  }
  .splitBannerArea .splitBannerTab:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.splitBannerArea .splitBannerTab .bookNowBtn {
  min-width: 150px;
  margin-top: 20px;
}

@media (max-width: 53.125em) {
  .splitBannerArea .splitBannerTab .bannerHeading {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media (max-width: 47.9375em) {
  .splitBannerArea .splitBannerTab .bannerHeading {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (max-width: 39.9375em) {
  .splitBannerArea .splitBannerTab .bannerHeading {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.splitBannerArea .splitBannerTab p {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 25px 15px;
  color: #fff;
}

@media (max-width: 63.9375em) {
  .splitBannerArea .splitBannerTab p {
    padding: 10px 10px 15px;
  }
}

@media (max-width: 39.9375em) {
  .splitBannerArea .splitBannerTab p {
    padding: 10px 0 15px;
  }
}

.splitBannerArea .splitBannerTab .splitBannerImageWrap {
  margin-bottom: 20px;
}

.splitBannerArea .splitBannerTab .splitBannerImageWrap .splitBannerImage {
  max-width: 200px;
  margin: auto;
  padding-bottom: 20px;
}

@media (max-width: 53.125em) {
  .splitBannerArea .splitBannerTab .splitBannerImageWrap .splitBannerImage {
    max-width: 150px;
  }
}

@media (max-width: 39.9375em) {
  .splitBannerArea .splitBannerTab .splitBannerImageWrap .splitBannerImage {
    padding-bottom: 0;
  }
}

.icon_128 {
  max-width: 128px !important;
}

.splitBannerArea {
  position: relative;
  display: flex;
  padding: 30px 0 0 0;
}

@media (max-width: 39.9375em) {
  .splitBannerArea {
    flex-direction: column;
  }
}

.splitBannerArea .splitBannerLeft {
  width: 50%;
  padding: 0 25px;
  border-right: 1px solid #25436d;
}

@media (max-width: 39.9375em) {
  .splitBannerArea .splitBannerLeft {
    width: 100%;
    margin-bottom: 30px;
    border-right: none;
    padding: 0 0 25px 0;
    border-bottom: 1px solid #25436d;
  }
}

.splitBannerArea .splitBannerLeft p {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 25px 15px;
  color: #fff;
}

@media (max-width: 63.9375em) {
  .splitBannerArea .splitBannerLeft p {
    padding: 10px 10px 15px;
  }
}

@media (max-width: 39.9375em) {
  .splitBannerArea .splitBannerLeft p {
    padding: 10px 0 15px;
  }
}

.splitBannerArea .splitBannerRight {
  width: 50%;
  padding: 0 25px;
}

@media (max-width: 39.9375em) {
  .splitBannerArea .splitBannerRight {
    width: 100%;
    padding: 25px 0 0 0;
  }
}

.splitBannerArea .splitBannerRight p {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 25px 15px;
  color: #fff;
}

@media (max-width: 63.9375em) {
  .splitBannerArea .splitBannerRight p {
    padding: 10px 10px 15px;
  }
}

@media (max-width: 39.9375em) {
  .splitBannerArea .splitBannerRight p {
    padding: 10px 0 15px;
  }
}

.splitBannerImageWrap {
  margin-bottom: 20px;
}

.splitBannerImageWrap .splitBannerImage {
  max-width: 200px;
  margin: auto;
  padding-bottom: 20px;
}

.icon_128 {
  max-width: 128px !important;
}

.imiLogoAbout {
  background: #fff;
  max-width: 270px;
  width: 100%;
  margin: 25px auto;
  padding: 18px 0;
  text-align: center;
}

.videoSection {
  background: #1f3655;
}

.videoSection .videoWrapper {
  position: relative;
  padding: 56.25% 0 0 0;
}

.videoSection .videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contactBannerArea {
  position: relative;
  display: flex;
  padding: 30px 0 0 0;
  flex-wrap: wrap;
}

.contactBannerArea .contactBannerTab {
  max-width: 33.33333333333333%;
  flex-basis: 33.33333333333333%;
  border-right: 1px solid #25436d;
  text-align: center;
}

.contactBannerArea .contactBannerTab:last-of-type {
  border-right: none;
}

@media (max-width: 47.9375em) {
  .contactBannerArea .contactBannerTab {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 30px;
    border-right: none;
    padding: 0 0 25px 0;
    border-bottom: 1px solid #25436d;
  }
  .contactBannerArea .contactBannerTab:last-of-type {
    border-bottom: none;
  }
}

.contactBannerArea .contactBannerTab .contactTabHeading {
  font-size: 26px;
  font-size: 1.625rem;
  color: #fff;
  font-weight: 500;
}

@media (max-width: 63.9375em) {
  .contactBannerArea .contactBannerTab .contactTabHeading {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media (max-width: 47.9375em) {
  .contactBannerArea .contactBannerTab .contactTabHeading {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.contactBannerArea .contactBannerTab .bannerImageWrap {
  margin-bottom: 20px;
}

.contactBannerArea .contactBannerTab .bannerImageWrap .bannerImage {
  max-width: 200px;
  margin: auto;
  padding-bottom: 20px;
}

.contactBannerArea .contactBannerTab p {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 25px 15px;
  color: #fff;
}

@media (max-width: 63.9375em) {
  .contactBannerArea .contactBannerTab p {
    padding: 10px 10px 15px;
  }
}

@media (max-width: 39.9375em) {
  .contactBannerArea .contactBannerTab p {
    padding: 10px 0 15px;
  }
}

.contactusBannerIcon {
  max-width: 128px !important;
}

.contactTelephone {
  color: #fff !important;
  font-weight: 500;
  border-bottom: none !important;
  transition-duration: none !important;
}

.contactTelephone span {
  max-width: 32px;
  display: inline-block;
  margin-right: 5px;
}

.mapBg {
  position: relative;
  width: 100%;
  height: 550px;
  overflow: hidden;
  background-size: cover;
  box-shadow: inset 0 -10px 10px -10px #767678;
}

.mapBg iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 550px;
  border: 0;
  background-size: cover;
}

.successText {
  padding: 15px;
  font-size: 18px;
  font-size: 1.125rem;
  color: #137400;
  text-align: center;
  background: #fff;
}

.successText p {
  color: #137400;
}

.errorText {
  padding: 15px;
  font-size: 18px;
  font-size: 1.125rem;
  color: red;
  text-align: center;
  background: #fff;
}

.errorText p {
  color: #137400;
}

.serviceFormWrapper {
  position: relative;
  max-width: 430px;
  width: 100%;
  margin: 30px auto;
  color: #fff;
}

.serviceFormWrapper .formTab {
  margin-bottom: 12px;
}

.serviceFormWrapper .formTab.firstTab {
  margin-bottom: 25px;
}

.serviceFormWrapper .formTab.extraGap {
  padding: 15px 0;
  margin-bottom: 0;
}

.serviceFormWrapper .formTab.termsOfUseWrap a {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: underline;
}

.serviceFormWrapper .formTab.termsOfUseWrap label.error {
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 16px;
  color: #f84348;
}

.serviceFormWrapper .formTab .captchaWrap {
  max-width: 305px;
  width: 100%;
  margin-top: 30px;
  overflow-x: auto;
}

.serviceFormWrapper .radioCheckboxLabel {
  position: relative;
  margin: 20px 0;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 300;
}

.serviceFormWrapper .radioCheckboxLabel.radioBtnWrap {
  margin-right: 30px;
}

.serviceFormWrapper .radioCheckboxLabel.checkboxLbl {
  display: block;
}

.serviceFormWrapper .radioCheckboxLabel input[type="radio"],
.serviceFormWrapper .radioCheckboxLabel input[type="checkbox"] {
  position: absolute;
  margin: 5px;
  padding: 0;
  /* for mobile accessibility (iOS Label Bug) */
  visibility: hidden;
}

.serviceFormWrapper .radioCheckboxLabel .label-visible {
  position: relative;
  padding-left: 2em;
  margin-bottom: 0;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 300;
  color: #fff;
  display: inline-block;
}

.serviceFormWrapper .radioCheckboxLabel .showRadioCheckbox {
  position: absolute;
  display: block;
  top: 0;
  left: 3px;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  background-color: white;
}

.serviceFormWrapper .radioCheckboxLabel .showRadioCheckbox.showRadio {
  border-radius: 50%;
}

.serviceFormWrapper .radioCheckboxLabel .showRadioCheckbox.showRadio:after {
  border-radius: 50%;
}

.serviceFormWrapper .radioCheckboxLabel .showRadioCheckbox:after {
  content: "✔";
  font-size: 20px;
  font-size: 1.25rem;
  padding-left: 5px;
  line-height: 1.4;
  color: #fff;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 28px;
  background: #73cd03;
  transform: translateX(-50%) translateY(-50%);
}

.serviceFormWrapper .radioCheckboxLabel input[type="radio"]:checked + div .showRadio:after,
.serviceFormWrapper .radioCheckboxLabel input[type="checkbox"]:checked + div .showRadioCheckbox:after {
  display: block;
}

.serviceFormWrapper .blockLabel {
  display: block;
  margin-bottom: 3px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 300;
  color: #fff;
}

.serviceFormWrapper label.error {
  font-size: 16px;
  font-size: 1rem;
  color: #f84348;
}

.serviceFormWrapper input[type="text"], .serviceFormWrapper input[type="email"] {
  display: block;
  max-width: 100%;
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  padding: 8px 10px;
  border-radius: 2px;
  font-size: 18px;
  font-size: 1.125rem;
  color: #000;
  outline: none;
}

.serviceFormWrapper input[type="text"].error, .serviceFormWrapper input[type="email"].error {
  border: 1px solid #f84348;
}

.serviceFormWrapper textarea {
  display: block;
  max-width: 100%;
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  padding: 8px 10px;
  min-height: 100px;
  resize: vertical;
  border-radius: 2px;
  font-size: 18px;
  font-size: 1.125rem;
  color: #000;
  outline: none;
}

.serviceFormWrapper textarea.error {
  border: 1px solid #f84348;
}

.serviceFormWrapper input[type="submit"] {
  display: block;
  max-width: 100%;
  width: 100%;
  border: 1px solid #73cd03;
  padding: 15px 10px;
  border-radius: 2px;
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  outline: none;
  margin-top: 20px;
  background: #73cd03;
  background: linear-gradient(to bottom, #73cd03 0%, #5baf03 100%);
}

.serviceFormWrapper input[type="submit"]:hover {
  background: linear-gradient(to bottom, #73cd03 0%, #73cd03 100%);
}

.cookiePrivacyTermsCol {
  margin-top: 30px;
  position: relative;
  width: 100%;
}

.cookiePrivacyTermsCol a {
  color: #fff;
  text-decoration: underline;
}

.cookiePrivacyTermsCol input[type="checkbox"] {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 27px;
  height: 27px;
  background: #000;
  font-size: 24px;
  border: none;
  outline: 3px solid #bbb;
}

.cookiePrivacyTermsCol input[type="checkbox"].error {
  outline: 3px solid #f84348;
}

.cookiePrivacyTermsCol label {
  color: #fff;
  display: block;
  margin-bottom: 0;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 300;
  padding-left: 2em;
  position: relative;
  transition: all 0.3s ease;
}

.cookiePrivacyTermsCol label.error {
  padding: 0 0 5px 0;
  display: block;
  color: #f84348;
  box-shadow: none;
}

.privayandtermswrap {
  position: relative;
  padding: 30px 0;
  color: #fff;
}

.privayandtermswrap h2 {
  font-size: 20px;
  font-family: 'Philosopher';
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
}

.privayandtermswrap p {
  font-size: 14px;
  padding-bottom: 15px;
  color: #fff;
}

.privayandtermswrap ul {
  list-style: none;
}

.privayandtermswrap ul li {
  position: relative;
  list-style: none;
  font-size: 14px;
  padding: 0 0 0 20px;
}

.privayandtermswrap ul li:before {
  width: 8px;
  height: 8px;
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 100%;
  background: #fff;
}

.privayandtermswrap a {
  color: #fff !important;
  border-bottom-color: #fff !important;
}

.privayandtermswrap table {
  border: 1px solid #fff;
}

.privayandtermswrap table th, .privayandtermswrap table td {
  border: 1px solid #fff;
  padding: 8px;
}

.responsiveTableWrap {
  max-width: 800px;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 30px;
}

.spread-cost-banner {
  background-color: #fff;
  padding: 30px 0;
  text-align: center;
  border-top: 2px solid #004071;
}

.spread-cost-banner .spread-cost-link {
  text-decoration: none;
}

.spread-cost-banner .spread-cost-link:hover .spread-cost-text {
  border-bottom-color: transparent;
}

.spread-cost-banner .spread-cost-heading {
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 300;
  color: #004071;
  text-align: center;
  line-height: 1.2;
}

@media (max-width: 63.9375em) {
  .spread-cost-banner .spread-cost-heading {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media (max-width: 47.9375em) {
  .spread-cost-banner .spread-cost-heading {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.spread-cost-banner .spread-cost-content {
  margin: 30px auto;
  display: flex;
  max-width: 100%;
  width: max-content;
  align-items: center;
  justify-content: center;
}

@media (max-width: 39.9375em) {
  .spread-cost-banner .spread-cost-content {
    margin-top: 15px;
    flex-direction: column;
  }
}

.spread-cost-banner .spread-cost-text {
  font-size: 24px;
  font-size: 1.5rem;
  color: #004071;
  font-weight: 300;
  margin-right: 20px;
  border-bottom: 1px solid #004071;
}

@media (max-width: 39.9375em) {
  .spread-cost-banner .spread-cost-text {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.spread-cost-banner .spread-cost-img {
  max-width: 300px;
  width: 100%;
}

.accreditationWrap {
  background: #57585a;
  padding: 20px 0;
}

.accreditationWrap .logsList {
  text-align: center;
}

.accreditationWrap .logsList li {
  display: inline-block;
  max-width: 230px;
  margin: 5px 10px;
}

.footer {
  background: #2e2e2e;
  padding: 50px 0;
}

.footer .bottomTabWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30px;
}

.footer .bottomTabWrapper .bottomTab {
  max-width: 25%;
  flex-basis: 25%;
  width: 100%;
  padding-right: 25px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 300;
  color: #7d7d7d;
}

.footer .bottomTabWrapper .bottomTab:nth-of-type(4) {
  padding-right: 0;
}

@media (max-width: 63.9375em) {
  .footer .bottomTabWrapper .bottomTab {
    max-width: 50%;
    flex-basis: 50%;
    text-align: center;
    padding-right: 0;
  }
  .footer .bottomTabWrapper .bottomTab:nth-of-type(1) {
    padding-bottom: 30px;
    border-bottom: 1px solid #444242;
    border-right: 1px solid #444242;
    order: 1;
  }
  .footer .bottomTabWrapper .bottomTab:nth-of-type(2) {
    padding-top: 30px;
    order: 4;
  }
  .footer .bottomTabWrapper .bottomTab:nth-of-type(3) {
    padding-bottom: 30px;
    border-bottom: 1px solid #444242;
    order: 2;
  }
  .footer .bottomTabWrapper .bottomTab:nth-of-type(4) {
    padding-top: 30px;
    border-right: 1px solid #444242;
    order: 3;
  }
}

@media (max-width: 39.9375em) {
  .footer .bottomTabWrapper .bottomTab {
    max-width: 100%;
    flex-basis: 100%;
  }
  .footer .bottomTabWrapper .bottomTab:nth-of-type(1) {
    border-right: none;
  }
  .footer .bottomTabWrapper .bottomTab:nth-of-type(3) {
    padding-top: 30px;
  }
  .footer .bottomTabWrapper .bottomTab:nth-of-type(4) {
    border-right: none;
    padding-bottom: 30px;
  }
  .footer .bottomTabWrapper .bottomTab:nth-of-type(2) {
    border-top: 1px solid #444242;
  }
}

.footer .bottomTabWrapper .bottomTab .tabHeading {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  padding-bottom: 25px;
}

.footer .bottomTabWrapper .bottomTab a {
  color: #7d7d7d;
}

.footer .bottomTabWrapper .bottomTab i {
  font-size: 32px;
  margin-right: 5px;
  margin-bottom: 5px;
  vertical-align: middle;
}

.footer .bottomTabWrapper .bottomTab ul li {
  padding: 5px 0;
}

.footer .bottomTabWrapper .bottomTab p {
  padding-bottom: 20px;
  line-height: 1.7;
}

.footer .bottomTabWrapper .bottomTab .imiLogo {
  max-width: 209px;
  width: 100%;
  padding-bottom: 20px;
}

@media (max-width: 63.9375em) {
  .footer .bottomTabWrapper .bottomTab .imiLogo {
    margin: auto;
  }
}

.footer .bottomTabWrapper .bottomTab .callNumber {
  font-size: 18px;
  font-size: 1.125rem;
  text-decoration: none;
}

.footer .bottomTabWrapper .bottomTab .emailReservationBtn {
  display: block;
  max-width: 200px;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  color: #7d7d7d;
  text-align: center;
  padding: 8px 0;
  text-decoration: none;
  border: 1px solid #c5c5c5;
}

.footer .bottomTabWrapper .bottomTab .emailReservationBtn:hover {
  background: #7d7d7d;
  color: #fff;
}

@media (max-width: 63.9375em) {
  .footer .bottomTabWrapper .bottomTab .emailReservationBtn {
    margin: 0 auto;
  }
}

@media (max-width: 63.9375em) {
  .footer .bottomTabWrapper .bottomTab .socialTab li {
    display: inline-block;
  }
  .footer .bottomTabWrapper .bottomTab .socialTab li:last-of-type a i {
    margin-right: 0;
  }
  .footer .bottomTabWrapper .bottomTab .socialTab a i {
    margin-right: 5px;
  }
  .footer .bottomTabWrapper .bottomTab .socialTab a span {
    display: none;
  }
}

.footer .copyrightCreditWrap {
  border-top: 1px solid #585858;
  padding: 30px 0;
  text-align: center;
}

.footer .copyrightCreditWrap .copyright {
  font-size: 14px;
  font-size: 0.875rem;
  color: #7d7d7d;
  padding-bottom: 10px;
}

.footer .copyrightCreditWrap .creditDesignDev {
  font-size: 12px;
  font-size: 0.75rem;
  color: #7d7d7d;
}

.footer .copyrightCreditWrap .creditDesignDev a {
  color: #fff;
}

*::-moz-selection {
  background: none repeat scroll 0 0 #1777af;
  color: #fafafa;
  text-shadow: none;
}

input:-moz-placeholder {
  font-size: 0.875em;
  line-height: 1.4;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  box-sizing: content-box;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0 none;
  padding: 0;
}

p {
  /*-moz-hyphens: auto;*/
}

@media print {
  * {
    background: none repeat scroll 0 0 transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }
  a, a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
}
