@import 'mixin';

.textContent{
	position: relative;

	@include MQ(639) {
		padding-bottom: 30px;
	}
	

	
}

.wrapTextMobile{
	position: relative;
	// padding-bottom: 45px;
	transition: max-height 0.15s ease-out;

	@include MQ(639) {
		max-height: 300px;
		overflow: hidden;
		// transition: max-height 0.15s ease-out;
		

		&.showFullText{
			max-height: 100%;
			padding-bottom: 40px;
			// transition: max-height 0.15s ease-out;
		}
	}


	.tapMetoShow{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		// left: 50%;
		// margin-left: -55px;
		text-align: center;
		display: none;
		cursor: pointer;
		// transition: max-height 0.15s ease-out;
		font-weight: 500;
    	text-transform: uppercase;

		padding: 10px 0px;
   		// width: 110px;
		line-height: 1.2;
		color: #1f3655;
		background: #ffffff;
		background: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 0%, #bab7b7 100%);


		@include MQ(639) {
			display: block;
		}
	}

	
}