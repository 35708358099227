@import 'modules/mixin';

.pageSection{
	position: relative;
	padding: 50px 0 60px;

	&.bgLightBlue{
		background: #e7ecf0;
	}
	&.bgWhite{
		background: #fff;
	}
	&.lightGray{
		background: #f6f6f6;
	}
	&.bgDarkBlue{
		background: #1f3655;
	}

	@include MQ(1023) {
		padding: 40px 0;
	}
	@include MQ(767) {
		padding: 30px 0;
	}

	.textHeadingWrap{
		padding-bottom: 30px;

		.textHeading{
			@include font-size(36px);
			font-weight: 300;	
			color: #004071;
			text-align: center;
			line-height: 1.2;

			@include MQ(1023) {
				@include font-size(30px);
			}
			@include MQ(767) {
				@include font-size(24px);
			}
		}
		.textSubHeading{
			@include font-size(18px);
			color: #004071;
			font-weight: 300;	
			display: block;
			text-align: center;

			@include MQ(1023) {
				@include font-size(16px);
			}
			@include MQ(767) {
				@include font-size(14px);
			}
		}
	}
	p{
		@include font-size(18px);
		color: #111;
		line-height: 1.7;
		font-weight: 300;
		padding-bottom: 20px;

		@include MQ(1023) {
			@include font-size(16px);
		}

		a{
			color: #000;
			padding-bottom: 2px;
			border-bottom: 1px solid #000;
			text-decoration: none;

			&:hover{
				border-bottom: 1px solid transparent;
			}
		}
	}
	.textCenter{
		text-align: center;
	}
}
.pageSectionList{
	text-align: center;
	margin-bottom: 50px;

	&.zeroBottomMargin{
		margin-bottom: 0;
	}

	@include MQ(767) {
		margin-bottom: 25px;
	}

	li{
		padding: 15px 0;
		border-bottom: 1px dashed #cfcfcf;
	}
}