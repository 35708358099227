@import 'mixin';

.imiLogoAbout{
	background: #fff;
	max-width: 270px;
	width: 100%;
	margin: 25px auto;
	padding: 18px 0;
	text-align: center;
}

.videoSection{
	background: #1f3655;

	.videoWrapper{		
		position:relative;
		padding: 56.25% 0 0 0;

		iframe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}