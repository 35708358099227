@import 'modules/mixin';

.testimonialSliderWrapper{
	text-align: center;
	position: relative;
	padding-top: 20px;
	padding-bottom: 40px;

	.flex-control-nav{
		display: none;
	}

	@include MQ(850) {
		padding-top: 0;

		.flex-direction-nav{
			display: none !important;
		}
		.flex-viewport{
			margin-bottom: 40px !important;
		}
		.flex-control-nav{
			display: block !important;
			bottom: auto;

			a{
				background: #d3d3d3 !important;
				box-shadow: none !important;
			}

			a.flex-active{
				background: #7a9ecf !important;
			}
		}
	}
	
}
.testimonialText{
	padding: 0 10%;

	@include MQ(767) {
		padding: 0;
	}

	p{
		@include font-size(18px);
		color: #383838;
		font-weight: 300;
		line-height: 1.8;

		@include MQ(767) {
			@include font-size(16px);
		}
	}
}
.testimonialAuthor{
	padding: 30px 10% 0;

	p{
		color: #ffaa06;
		@include font-size(16px);
	}
}
