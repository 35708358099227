@import 'modules/mixin';

.privayandtermswrap{
	position: relative;
	padding: 30px 0;
	color: #fff;
}
// .privayandtermswrap h1{
// 	font-size: 24px;
// 	font-weight: bold;
// 	margin-bottom: 20px;
// 	color: #646464;
// }
.privayandtermswrap h2{
	font-size: 20px;
	font-family: 'Philosopher';
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 10px;
	color: #fff;
}
.privayandtermswrap p{
	font-size: 14px;
	padding-bottom: 15px;
	color: #fff;
}
.privayandtermswrap ul{
	list-style: none;
}
.privayandtermswrap ul li{
	position: relative;
	list-style: none;
	font-size: 14px;
	padding: 0 0 0 20px;
}
.privayandtermswrap ul li:before{
	width: 8px;
	height: 8px;
	content: '';
	position: absolute;
	top: 4px;
	left: 0;
	border-radius: 100%;
	background: #fff;
}
.privayandtermswrap a{
	color: #fff !important;
	// text-decoration: underline;
	border-bottom-color: #fff !important;
}
.privayandtermswrap table{
	border: 1px solid #fff;

}
.privayandtermswrap table th, .privayandtermswrap table td{
	border: 1px solid #fff;
	padding: 8px;
}
.responsiveTableWrap{
	max-width: 800px;
	width: 100%;
	overflow-x: auto;
	margin-bottom: 30px;
}