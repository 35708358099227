@import 'modules/mixin';

.responsiveHeaderWrap{
    // position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #1f3655;
    display: none !important;
    z-index: 1150;

    @include MQ(1279) {
        display: block !important;
    }
    .responsiveHeaderTopWrap{
        display: flex;
        border-bottom: 1px solid #2e4e7b;

        .navBtn{
            // position: relative;
            max-width: 50%;
            flex-basis: 50%;
            border-right: 1px solid #2e4e7b;
            padding: 10px 15px;
            text-align: center;
            @include font-size(18px);
            font-weight: 500;
            text-transform: uppercase;
            color: #fff;
            line-height: 2;
            cursor: pointer;

            @include MQ(539) {
                max-width: 45%;
                flex-basis: 45%;
                text-align: left;
                padding: 10px 5px 10px 15px;
                @include font-size(16px);
                line-height: 2.5;
            }

            &.activeNav{
                span{
                    // width: 20px;
                    // transform: rotate(0deg);
                    background: transparent;

                    &:before{
                        // width: 65%;
                        margin-top: 0;
                        background: #ff0000;
                        // margin-left: -1px;
                        transform: rotate(45deg);
                    }
                    &:after{
                        // width: 65%;
                        margin-top: 0;
                        background: #ff0000;
                        // margin-left: -1px;
                        transform: rotate(-45deg);
                    }
                }
            }

            span{
                position: relative;
                width: 20px;
                height: 2px;
                background: #60b502;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                margin-top: -3px;
                transition: all 0.3s ease;

                &:before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    margin-top: -8px;
                    background: #60b502;
                    transition: all 0.3s ease;
                }
                &:after{
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    margin-top: 8px;
                    background: #60b502;
                    transition: all 0.3s ease;
                }
            }

        }
        .responsiveHeaderCallTab{
            max-width: 50%;
            flex-basis: 50%;
            // padding: 10px 15px;
            text-align: right;
            font-family: 'Roboto';
            @include font-size(20px);
            color: #fff;
            line-height: 0.6em;
            letter-spacing: 0.03em;
            text-align: center;

            @include MQ(539) {
                max-width: 55%;
                flex-basis: 55%;
                text-align: right;
                // padding: 10px 15px 10px 5px;
            }

            a{
                // height: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                color: #fff;
                text-decoration: none !important;

                i{
                    color: #60b502;
                }
            }
            
            // span{
            //     font-family: 'Roboto' !important;
            //     @include font-size(10px);
            //     text-transform: uppercase;
            //     font-weight: 300;
            //     letter-spacing: 0.3em;
            //     color: #edc26b !important;
            // }
        }
    }
    .responsiveNavigationWrap{
        background: #f0f2f4;
        // display: none;
        display: block;
        position: relative;
        width: 50%;
        left: -100%;
        transition: all 0.4s ease;

        @include MQ(539) {
            width: 100%;
        }

        ul{
            position: absolute;
            // top: 0;
            left: 0;
            right: 0;
            background: #f0f2f4;
            z-index: 1000;
            // overflow: auto;
            padding-bottom: 100px;
            @include calc( height, '100vh - 60px');
            overflow-y: scroll;
            

            li{
                
                display: list-item;

                a{
                    // animation: smooth-entry 0.4s;
                    display: block;
                    @include font-size(18px);
                    font-weight: 500;
                    color: #004071;
                    text-decoration: none;
                    padding: 8px 15px;
                    border-bottom: 1px solid #d1dfed;
                    text-align: left;

                    i{
                        width: 32px;
                        margin-right: 15px;
                    }

                    img{
                        width: 32px;
                    }

                    // @include MQ(539) {
                    //     text-align: left;
                    // }
                }
                ul{
                    position: relative;
                    a{
                        font-weight: 300;
                    }
                }
            }
        }
        &.activeNav{
            // display: block;
            left: 0;
        }
    }
}

.overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 110;
    background: rgba(#000, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;

    &.activeNav{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s;

        @include MQ(1280) {
            visibility: hidden !important;
        }
    }
}
html.activeNav{
    position: fixed;
    margin: 0; 
    height: 100%; 
    overflow: hidden;
}
