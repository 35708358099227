.spread-cost-banner {
    background-color: #fff;
    padding: 30px 0;
    text-align: center;
    border-top: 2px solid #004071;

    .spread-cost-link {
        text-decoration: none;

        &:hover {
            .spread-cost-text {
                border-bottom-color: transparent;
            }
        }
    }
    
    .spread-cost-heading {
        @include font-size(36px);
            font-weight: 300;
            color: #004071;
            text-align: center;
            line-height: 1.2;
        
            @include MQ(1023) {
                @include font-size(30px);
            }        
            @include MQ(767) {
                @include font-size(24px);
            }
    }
    .spread-cost-content {
        margin: 30px auto;
        display: flex;
        max-width: 100%;
        width: max-content;
        align-items: center;
        justify-content: center;

        @include MQ(639) {
            margin-top: 15px;
            flex-direction: column;
        }
    }
    .spread-cost-text {
        @include font-size(24px);
        color: #004071;
        font-weight: 300;
        margin-right: 20px;
        border-bottom: 1px solid #004071;

        @include MQ(639) {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
    .spread-cost-img {
        max-width: 300px;
        width: 100%;
    }
}