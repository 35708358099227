@import 'mixin';

.servicesBannerArea{
	position: relative;
	padding: 20px 0 0 0;
	text-align: center;

	@include MQ(767) {
		padding: 0;
	}

	p{
		color: #fff;
	}

	.animatedIconWrap{
		text-align: center;
		padding-bottom: 20px;

		.animatedIcon{
			max-width: 200px;
			margin: auto;
			padding-bottom: 20px;
		}
	}
	.bannerHeading{
		@include font-size(30px);
		color: #fff;
		font-weight: 500;

		@include MQ(1023) {
			@include font-size(26px);
		}
		@include MQ(767) {
			@include font-size(22px);
		}
		@include MQ(539) {
			@include font-size(20px);
		}

		.highlight{
			color: #f92800;
			font-weight: 500;
		}
	}
	.bannerSubHeading{
		@include font-size(24px);
		color: #fff;
		font-weight: 300;
		
		@include MQ(1023) {
			@include font-size(18px);
		}
		@include MQ(767) {
			@include font-size(16px);
		}
	}
	.bookNowBtn{
		border-radius: 5px;
		display: inline-block;
		padding: 12px 20px 10px;
		color: #fff;
		text-decoration: none;
		text-transform: uppercase;
		text-align: center;

		margin-top: 4px;
		background: rgb(115,205,3);
		background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(91,175,3,1) 100%);

		@include MQ(767) {
			padding: 10px 20px 8px;
		}

		&:hover{
			background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(115,205,3,1) 100%);
		}
	}
}

.serviceProcessTabsWrap{
	position: relative;
	display: flex;
	flex-wrap: wrap;

	.serviceProcessTab{
		width: 32.52032520325203%; // 400 / 1230
		margin-right: 1.21951219512195%; // 15 / 1230
		margin-bottom: 15px;

		@include MQ(767) {
			width: 100%;
			margin-right: 0;
		}

		&:nth-of-type(3n) {
			margin-right: 0;
			margin-bottom: 15px;
		}

		.imgWrap{
			text-align: center;
		}
		.textWrap{
			text-align: center;
			@include font-size(18px);
			padding: 10px;

			p{
				// @include font-size(18px);
				font-weight: 300;
			}
		}
	}
}