@import 'mixin';

.sliderWrap{
	position: relative;
	padding: 0 0 0 0;

	@include MQ(850) {
		padding-top: 0;

		.flex-direction-nav{
			display: none !important;
		}
		.flex-viewport{
			margin-bottom: 40px !important;
		}
		.flex-control-nav{
			display: block !important;
			bottom: -30px;

			a{
				background: #d3d3d3 !important;
				box-shadow: none !important;
			}

			a.flex-active{
				background: #7a9ecf !important;
			}
		}
	}
}

.sliderContentWrap{
	position: relative;
	padding: 80px 0;
	text-align: center;

	@include MQ(1023) {
		padding: 50px 0;
	}
	@include MQ(639) {
		padding: 30px 0;
	}

	.iconWrap{
		text-align: center;

		img{
			width: auto !important;
			margin: auto;
		}
	}
	.sliderMainHeading{
		@include font-size(48px);
		font-weight: 500;
		color: #cb1d2a;
		padding: 20px 0 10px;

		@include MQ(1024) {
			@include font-size(36px);
		}
		@include MQ(767) {
			@include font-size(30px);
		}
		@include MQ(539) {
			@include font-size(24px);
		}
	}
	.sliderText{
		@include font-size(30px);
		font-weight: 500;
		color: #004071;
		padding: 0 0 20px;

		@include MQ(1024) {
			@include font-size(24px);
		}
		@include MQ(767) {
			@include font-size(18px);
		}
		@include MQ(539) {
			@include font-size(16px);
		}
	}
	.sliderBtn{
		border-radius: 5px;
		display: inline-block;
		padding: 12px 20px 10px;
		color: #fff;
		text-decoration: none;
		text-transform: uppercase;
		text-align: center;

		margin-top: 4px;
		background: rgb(115,205,3);
		background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(91,175,3,1) 100%);

		@include MQ(767) {
			padding: 10px 20px 8px;
		}

		&:hover{
			background: linear-gradient(to bottom, rgba(115,205,3,1) 0%,rgba(115,205,3,1) 100%);
		}
	}
}

.sliderVideo{
	position: relative;
	padding-bottom: 56.25%;
}
.sliderVideo iframe{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}